<template>
	<div class="pl-pay-success-wrap pl-flex-start">
		<div class="pl-success-icon">
			<img src="./TF-icon/T.png">
		</div>
		<div class="pl-pay-for-results" style="color: #d9262c;">支付成功</div>
		<div class="pl-view-or-return pl-flex">
			<a :href="`/user/order/detail?id=${$route.query.id}`">查看订单</a>
			<a href="/">返回首页</a>
		</div>
		<div class="pl-recommend-commodities" v-if="list.length">
			<div class="module-title">推荐商品</div>
			<div class="pl-recommend-commodities-content">
				<template v-for="(item, index) in list">
					<a :href="`/goods/detail?id=${item.id}`" :key="index">
						<div class="pl-product-image">
							<img :src="item.cover" alt="">
						</div>
						<div class="pl-product-content">
							<div class="pl-product-name">{{ item.name }}</div>
							<!-- <div class="pl-product-introduction">气垫元件</div> -->
							<!-- <div class="pl-product-price">￥50.00</div> -->
						</div>
					</a>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import {recommandGoods} from '@/api/shoping'

export default {
	data() {
		return {
			list: []
		}
	},
	created() {
		recommandGoods().then((res) => {
			let list = res || []
			list.forEach(item => {
				let arr = item.goods || []
				this.list.push(...arr)
			})
		})
	}
}
</script>
<style lang="scss" scoped>
* {
	font-size: 16px;
}

a {
	display: block;
	text-decoration: none;
	color: #000;
}

.pl-pay-success-wrap {
	flex-direction: column;
}

.pl-success-icon {
	width: 100px;
	height: 100px;
	margin: 100px 0px 20px;
}

.pl-pay-for-results {
	color: #D9262C;
}

.pl-success-icon img {
	width: 100px;
}

.pl-view-or-return {
	width: 380px;
	margin: 30px;
}

.pl-view-or-return a {
	width: 170px;
	line-height: 36px;
	color: #d9262c;
	background-color: #ffffff;
	border: 1px #d9262c solid;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pl-view-or-return a:hover {
	box-shadow: 0px 0px 2px #d9262c;
}

.pl-view-or-return a:active {
	color: #ffffff;
	background-color: #d9262c;
}

.pl-recommend-commodities-content {
	//width: 810px;
	display: flex;
	flex-wrap: wrap;
}

.pl-recommend-commodities-content a {
	//height: 120px;
	width: 25%;
  background: #ffffff;
	padding: 20px;
	border: .1px solid rgb(216, 216, 216);
	margin: 0px -1px -1px 0px;
	div {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.pl-product-image,
.pl-product-content {
	border-radius: 5px;
	overflow: hidden;
}

.pl-product-image img {
	width: 82px;
	border-radius: 5px;
}

.pl-product-image {
	width: 82px;
}

.pl-product-content {
	position: relative;
	width: 60%;
}

.pl-product-content div {
	margin-left: 5px;
}

.pl-product-name {
	margin-top: 10px;

}

.pl-product-introduction {
	font-size: 14px;
	margin-top: 5px;
}

.pl-product-price {
	position: absolute;
	bottom: 10px;
	color: #3385FF;
}
</style>
